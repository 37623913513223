import './App.css';
import "aos/dist/aos.css";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import React from 'react'
import ScrollToBottom from 'react-scroll-to-bottom';
import AOS from "aos";
import CsvDownloadButton from 'react-json-to-csv'
import Skeleton from '@mui/material/Skeleton';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';

const getgeminilist = (req) => {
  if (req == 1) {
  return '1'
  }
  if (req == 1.5) {
    return '1.5'
    }
  }

function Chatbyone({setSession, gem, setC}) {
  const app = React.useRef();
const [chatHistory , setChat] = React.useState([])
const [chatHis , setChatHis] = React.useState([])
const [loadProcess , setLoad] = React.useState(false)
const [pro , setPro] = React.useState(false)
const [con , setCon] = React.useState(false)

const Load = (e) => {
  e.preventDefault()
  if (e.target[0].value == '') {
    return;
  }
  console.log(e.target[0].value)
  setLoad(true)
  let obj = {
    user: "Me: " + e.target[0].value,
    bot: ""
  }
  setChat(chatHistory => [...chatHistory, obj]);  
  setC(chatHistory)
  const chatx = [...chatHis] 
  if (con) {
    chatx.push({
      "role": "user",
      "parts": [
          {
              "text": e.target[0].value
          }
      ]
    })
    setChatHis(chatx);
  }
setPro(true)
setTimeout(() => {
  window.scrollTo(0, document.body.scrollHeight);
}, 100);
setSession(true)
  fetch(encodeURI(con ? 'https://cpxstatusservice.azurewebsites.net/api/chatai/testaichat?model=' + getgeminilist(gem) : 'https://cpxstatusservice.azurewebsites.net/api/chatai/testai?model=' + getgeminilist(gem)), {
    method: 'post', // or 'PUT'
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: con ? JSON.stringify(chatx) : JSON.stringify({
      p: e.target[0].value
    })
    })
    .then(response => response.json())
    .then(data => {
      setPro(false)
        if (data.status == true) {
          if (con) {
            chatx.push({
              "role": "model",
              "parts": [
                  {
                      "text": data.message
                  }
              ]
            })
            setChatHis(chatx);
          }
          obj = {
            user: "Me: " + e.target[0].value,
            bot: data.message
          }
          e.target.reset();
        } else {
          obj = {
            user: "Me: " + e.target[0].value,
            bot: "Exprected error: " + data.message
          }
        }
        const newState = [...chatHistory] // creates a copy of the current array
        newState[chatHistory.length] = obj // set the position of the array to payload (or whatever should be the new value)
        setChat(newState)  
        setC(newState)
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
        setTimeout(() => {
          var objDiv = document.getElementById("root");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1000);
        setTimeout(() => {
          setLoad(false)
        }, 5000);
    })
    .catch((error) => {
    console.error('Error:', error);
    });
}

React.useEffect(() => {
  AOS.init({ duration: 800 });
}, [])

  return (
    <div>
      <ScrollToBottom mode='bottom'>
        <div style={{marginBottom: app.current != null ? app.current.offsetHeight +10 :200}}>
        {
          chatHistory.map((item) => (
            <div key={item.id}>
            <div className='d-flex justify-content-end mt-3 mb-3' data-aos="fade-down-left">
            <div className="card">
                <div className="card-body">
                  {item.user}
                </div>
              </div>
            </div>
    
            {item.bot != '' && (
                    <div className={'d-flex justify-content-start ' + (window.innerWidth < 700 ? 'w-75' : 'w-50')} data-aos="fade-down-right">
                    <div className="card">
                        <div className="card-body">
                        <Markdown key={item.bot} remarkPlugins={[remarkGfm]}>{item.bot}</Markdown>
                        </div>
                      </div>
                    </div>
            )}
             {item.bot == '' && pro && (
                    <div className={'d-flex justify-content-start ' + (window.innerWidth < 700 ? 'w-75' : 'w-50')} data-aos="fade-down-right">
                    <div className="card">
                        <div className="card-body">
                        <Skeleton variant="text" sx={{ width: 400, fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ width: 400,fontSize: '1rem' }} />
                        </div>
                      </div>
                    </div>
            )}
            {con == false && (
            <hr/>
            )}
            </div>
    
          ))
        }
        </div>

      </ScrollToBottom>


      <div className="fixed-bottom bg-light" ref={app}>
      <form className='d-flex justify-content-center card-body' onSubmit={(e) => Load(e)} autoComplete='off'>
  <div className="form-row container align-items-center">
    <div className="col-md col-12">
      <textarea className="form-control" id="inlineFormInput" placeholder='Enter your prompt' disabled={loadProcess} rows="2"></textarea>
    </div>
    <div className="col-md-auto col-12">
    <div class="btn-group mt-1" role="group" aria-label="Basic example">
      <button type="submit" className="btn btn-lg mt-2 w-100 btn-primary mb-2" disabled={loadProcess}>Send</button>
</div>
    </div>
  </div>
</form>
      </div>
    </div>
  );
}
function Chatfull({setSession, gem, setC}) {
  const app = React.useRef();
const [chatHistory , setChat] = React.useState([])
const [chatHis , setChatHis] = React.useState([])
const [loadProcess , setLoad] = React.useState(false)
const [pro , setPro] = React.useState(false)
const [con , setCon] = React.useState(true)

const Load = (e) => {
  e.preventDefault()
  if (e.target[0].value == '') {
    return;
  }
  console.log(e.target[0].value)
  setLoad(true)
  let obj = {
    user: "Me: " + e.target[0].value,
    bot: ""
  }
  setChat(chatHistory => [...chatHistory, obj]);
  setC(chatHistory)
  const chatx = [...chatHis] 
  if (con) {
    chatx.push({
      "role": "user",
      "parts": [
          {
              "text": e.target[0].value
          }
      ]
    })
    setChatHis(chatx);
  }
setPro(true)
setTimeout(() => {
  window.scrollTo(0, document.body.scrollHeight);
}, 100);
setSession(true)
  fetch(encodeURI(con ? 'https://cpxstatusservice.azurewebsites.net/api/chatai/testaichat?model=' + getgeminilist(gem) : 'https://cpxstatusservice.azurewebsites.net/api/chatai/testai?model=' + getgeminilist(gem)), {
    method: 'post', // or 'PUT'
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: con ? JSON.stringify(chatx) : JSON.stringify({
      p: e.target[0].value
    })
    })
    .then(response => response.json())
    .then(data => {
      setPro(false)
        if (data.status == true) {
          if (con) {
            chatx.push({
              "role": "model",
              "parts": [
                  {
                      "text": data.message
                  }
              ]
            })
            setChatHis(chatx);
          }
          obj = {
            user: "Me: " + e.target[0].value,
            bot: data.message
          }
          e.target.reset();
        } else {
          obj = {
            user: "Me: " + e.target[0].value,
            bot: "Exprected error: " + data.message
          }
        }
        const newState = [...chatHistory] // creates a copy of the current array
        newState[chatHistory.length] = obj // set the position of the array to payload (or whatever should be the new value)
        setChat(newState)
        setC(newState)
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
        setTimeout(() => {
          var objDiv = document.getElementById("root");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1000);
        setTimeout(() => {
          setLoad(false)
        }, 5000);
    })
    .catch((error) => {
    console.error('Error:', error);
    });
}

React.useEffect(() => {
  AOS.init({ duration: 800 });
}, [])

  return (
    <div>
      <ScrollToBottom mode='bottom'>
        <div style={{marginBottom: app.current != null ? app.current.offsetHeight +10 :200}}>
        {
          chatHistory.map((item) => (
            <div key={item.id}>
            <div className='d-flex justify-content-end mt-3 mb-3' data-aos="fade-down-left">
            <div className="card">
                <div className="card-body">
                  {item.user}
                </div>
              </div>
            </div>
    
            {item.bot != '' && (
                    <div className={'d-flex justify-content-start ' + (window.innerWidth < 700 ? 'w-75' : 'w-50')} data-aos="fade-down-right">
                    <div className="card">
                        <div className="card-body">
                        <Markdown key={item.bot} remarkPlugins={[remarkGfm]}>{item.bot}</Markdown>
                        </div>
                      </div>
                    </div>
            )}
             {item.bot == '' && pro && (
                    <div className={'d-flex justify-content-start ' + (window.innerWidth < 700 ? 'w-75' : 'w-50')} data-aos="fade-down-right">
                    <div className="card">
                        <div className="card-body">
                        <Skeleton variant="text" sx={{ width: 400,fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ width: 400,fontSize: '1rem' }} />
                        </div>
                      </div>
                    </div>
            )}
            {con == false && (
            <hr/>
            )}
            </div>
    
          ))
        }
        </div>

      </ScrollToBottom>


      <div className="fixed-bottom bg-light" ref={app}>
      <form className='d-flex justify-content-center card-body' onSubmit={(e) => Load(e)} autoComplete='off'>
  <div className="form-row container align-items-center">
    <div className="col-md col-12">
      <textarea className="form-control" placeholder='Enter your prompt' id="inlineFormInput" disabled={loadProcess} rows="2"></textarea>
    </div>
    <div className="col-md-auto col-12">
    <div class="btn-group mt-1" role="group" aria-label="Basic example">
      <button type="submit" className="btn btn-lg mt-2 w-100 btn-primary mb-2" disabled={loadProcess}>Send</button>
</div>
    </div>
  </div>
</form>
      </div>
    </div>
  );
}

function App() {
  const [gem , setGem] = React.useState(1)
  const [value, setValue] = React.useState('1');
  const [running, setRun] = React.useState(false);
  const [draw, setDraw] = React.useState(false);
  const [chatHistory , setChat] = React.useState([])

  const handleChange = (event, newValue) => {
    if (running) {
      if (window.confirm('Are you sure to swtich to another tab. Curent session will be clear') == true) {
        setValue(newValue);
        setRun(false)
        setChat([])
      }
    } else {
      setValue(newValue);
      setRun(false)
      setChat([])
    }
  };

  function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

  return (
    <div style={{ width: '100%', typography: 'body1' }}>
      <Drawer open={draw} onClose={() => setDraw(!draw)} sx={{overflowY: 'hidden'}}>
          <div class="col-12 form-group mt-5">
          <label for="exampleFormControlSelect1">Choose Model</label>
          <select class="form-control" value={gem} onChange={(e) => setGem(parseFloat(e.target.value))}>
            <option value={1}>Gemini Pro 1.0</option>
            <option value={1.5}>Gemini Pro 1.5</option>
          </select>
        <CsvDownloadButton data={chatHistory} delimiter=',' className="btn btn-success mt-5">Export Chat History</CsvDownloadButton>
        </div>

      </Drawer>
      <TabContext value={value}>
        <div style={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          <IconButton onClick={() => setDraw(!draw)}>
            <MenuIcon />
          </IconButton>
            <Tab label="Chat Part" value="1" />
            <Tab label="Chat Room" value="2" />
            <h6 className='setGemi' style={{position:'fixed',right:20, top: 15}}>{'Gemini Pro ' + gem.toFixed(1)}</h6>
          </TabList>
        </div>
        <TabPanel value="1"><Chatbyone gem={gem} setC={(v) => setChat(v)} setSession={(v) => setRun(v)} /></TabPanel>
        <TabPanel value="2"><Chatfull gem={gem} setC={(v) => setChat(v)} setSession={(v) => setRun(v)} /></TabPanel>
      </TabContext>
    </div>
  );
}

export default App;
